.text-input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
}

.text-input,
.title-input,
.subtitle-input,
.date-input,
.color-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
}

.text-input {
    height: 150px;
    resize: none;
}

.color-picker-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.color-picker-label {
    font-size: 16px;
    color: #fff;
}

.color-input {
    width: 50px;
    height: 40px;
    padding: 0;
}