.download-buttons {
    display: flex;
    gap: 10px;
    margin: 20px 0;
}

.download-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.download-buttons button:hover {
    background-color: #0056b3;
}