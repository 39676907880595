.app-container {
  max-width: 900px;
  /* Adjusted to fit more content comfortably */
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #282c34;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

h1 {
  margin-bottom: 20px;
}

.text-input-container,
.background-selection,
.download-buttons,
canvas {
  margin-bottom: 20px;
}

input,
textarea {
  color: #333;
}